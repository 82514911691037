import React, { useState } from 'react';
import { SmallChart, smallChartsData } from './components/SmallCharts';
import { LargeChart, largeCharts } from './components/LargeCharts';
import PollTable from './components/PollTable';

function App() {
    const [selectedChart, setSelectedChart] = useState(largeCharts.nationalPresidential);

    return (
        <div className="max-w-6xl mx-auto p-4 font-sans">
            <header className="mb-6">
                <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                        <div className="flex justify-center items-center">
                            <div className="text-center">
                                <h1 className="text-3xl font-bold" style={{ fontFamily: 'Georgia, serif' }}>Secim 2028</h1>
                                <p className="text-sm text-gray-600">Son Güncelleme Ekm. 13, 2024</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-sm">
            <span className="font-bold">2024</span>
            <span className="mx-2 text-gray-400">|</span>
            <span>RECENT POLLING AVERAGE UPDATES</span>
        </div> */}
                </div>
                <div className="h-1 bg-gradient-to-r from-blue-500 to-red-500"></div>
            </header>


            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 mb-4">
                {Object.entries(smallChartsData).map(([category, states]) =>
                    Object.entries(states).map(([state, poll]) => (
                        <SmallChart
                            key={`${category}-${state}`}
                            data={poll.data}
                            title={poll.title}
                            onClick={() => setSelectedChart(poll)}
                        />
                    ))
                )}
            </div>

            <div className="text-center my-6">
                <h2 className="text-lg font-semibold">Check out our popular polling averages</h2>
                <div className="h-px bg-gray-300 mt-2"></div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                {Object.entries(largeCharts).map(([key, chart]) => (
                    <div key={key}>
                        {chart.component ? (
                            <chart.component />
                        ) : (
                            <LargeChart data={chart.data} title={chart.title} />
                        )}
                    </div>
                ))}
            </div>

            <div className="mt-6 flex justify-between items-center">
                <div className="flex space-x-2">
                    <select className="border rounded-md py-2 px-4">
                        <option>President: general election</option>
                    </select>
                    <select className="border rounded-md py-2 px-4">
                        <option>Nevada</option>
                    </select>
                    <select className="border rounded-md py-2 px-4">
                        <option>2024</option>
                    </select>
                </div>
            </div>

            <PollTable />
        </div>
    );
}

export default App;
