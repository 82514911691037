import React, { useEffect, useState } from 'react';

const PollTable = () => {
  const [pollData2, setPollData] = useState([]);

  useEffect(() => {
    const fetchPollData = async () => {
      try {
        const response = await fetch('https://secimdb.nuritas.me/api/poll_history');
        const data = await response.json();
        setPollData(data);
      } catch (error) {
        console.error('Error fetching poll data:', error);
      }
    };

    fetchPollData();
  }, []);

  // Function to group data by month
  const groupByMonth = (data) => {
    return data.reduce((acc, curr) => {
      const month = `${curr.yil}-${curr.ay_int.toString().padStart(2, '0')}`;
      if (!acc[month]) acc[month] = [];
      acc[month].push(curr);
      return acc;
    }, {});
  };

  const groupedData = groupByMonth(pollData2);

  return (
    <>
      {Object.keys(groupedData).map((month) => (
        <div key={month}>
          <h2 className="text-xl font-bold mt-4">{month}</h2>
          <table className="w-full mt-2">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-left">Tarihler</th>
                <th className="px-4 py-2 text-left">Örneklem</th>
                <th className="px-4 py-2 text-left">Şirket</th>
                <th className="px-4 py-2 text-left">Sonuç</th>
                <th className="px-4 py-2 text-left">Fark</th>
              </tr>
            </thead>
            <tbody>
              {groupedData[month].map((poll) => (
                <tr key={poll.id} className="border-b">
                  <td className="px-4 py-2">{poll.tarih}</td>
                  <td className="px-4 py-2">{poll.orneklem}</td>
                  <td className="px-4 py-2">{poll.sirket}</td>
                  <td className="px-4 py-2">
                    <span className="text-orange-400 font-semibold">AKP {poll.akp}%</span>
                    <span className="mx-1">-</span>
                    <span className="text-red-400 font-semibold">{poll.chp} CHP%</span>
                  </td>
                  <td className="px-4 py-2">{poll.fark}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr className="my-4" />
        </div>
      ))}
    </>
  );
};

export default PollTable;